import { useState, useEffect } from 'react';
import i18n from '../helpers/i18n';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

export default function useI18n() {

  const [ ready, setReady ] = useState(false);

  useEffect(() => {

    i18n
      .use(initReactI18next)
      .use(LanguageDetector)
      .use(HttpApi)
      .init({
        load: 'languageOnly',
        fallbackLng: 'en',
        detection: {
          order: ['htmlTag'],
        },
        backend: {
          loadPath: '/translations?lang={{lng}}',
        }
      })
      .then(() => {
        setReady(true);
      });

  }, []);

  return { ready };

}