import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { get } from '../../api/requests';

import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Cookies from 'js-cookie';

export default function LanguagePicker() {

  const [ locale, setLocale ] = useState('en');
  const { i18n } = useTranslation();

  const query = useQuery({
    queryKey: ['locales'],
    queryFn: () => get('/locales').then(res => res.json()),
  });

  useEffect(() => {
    const locale = document.documentElement.lang;
    if (locale) setLocale(locale);
  }, []);

  const locales = query.data;

  function changeLocale(locale) {
    setLocale(locale);
    Cookies.set('subthreshold_locale', locale);
    i18n.changeLanguage(locale);
    document.documentElement.lang = locale;
  }

  if (query.isLoading) return null;

  // available locale array will be empty unless 
  // enabled on the server
  if (!locales || locales.length === 0) return null;

  return (
    <DropdownButton size="sm" title={locale} variant="light">
      { locales.map(locale => (
        <Dropdown.Item as="button" key={locale} onClick={() => changeLocale(locale)}>
          {locale}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
}