import React from "react";

// <rect width="300" height="300" fill="white" />
const Paws = () => (
  <svg viewBox="0 0 300 300" fill="none">
    <g clipPath="url(#paws-clip0)">
      <path
        d="M58.386 201.415C55.0847 162.742 132.43 159.441 129.129 201.415C125.827 243.389 140.448 300.454 140.448 300.454H48.0104C52.0624 261.777 61.6874 240.087 58.386 201.415Z"
        fill="#3F324F"
      />
      <ellipse
        cx="74.5855"
        cy="89.9304"
        rx="22.9938"
        ry="32.4635"
        transform="rotate(-14.2833 74.5855 89.9304)"
        fill="#3F324F"
      />
      <ellipse
        cx="109.148"
        cy="89.8919"
        rx="25.5245"
        ry="32.4635"
        transform="rotate(18.5289 109.148 89.8919)"
        fill="#3F324F"
      />
      <ellipse
        cx="92.0549"
        cy="150.271"
        rx="40.3985"
        ry="57.9899"
        transform="rotate(89.4535 92.0549 150.271)"
        fill="#3F324F"
      />
      <ellipse
        cx="102.562"
        cy="173.536"
        rx="40.3985"
        ry="57.9899"
        transform="rotate(89.4535 102.562 173.536)"
        fill="#3F324F"
      />
      <ellipse
        cx="85.2077"
        cy="170.876"
        rx="40.3985"
        ry="57.9899"
        transform="rotate(77.0326 85.2077 170.876)"
        fill="#3F324F"
      />
      <path
        d="M161.165 137.925C151.595 158.212 134.123 167.98 121.374 161.966C108.624 155.952 106.046 134.63 115.616 114.344C125.186 94.0569 143.279 82.4868 156.029 88.501C168.778 94.5153 170.734 117.639 161.165 137.925Z"
        fill="#3F324F"
      />
      <ellipse
        cx="48.0317"
        cy="128.146"
        rx="26.6984"
        ry="41.9186"
        transform="rotate(-29.8779 48.0317 128.146)"
        fill="#3F324F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.551 146.095C117.17 131.987 105.838 121.334 92.2849 121.415C78.6893 121.497 67.4591 132.353 65.2969 146.553C52.4821 152.912 44.8791 164.912 47.5396 176.4C50.8084 190.514 68.3333 198.512 86.6825 194.262C88.4073 193.863 90.078 193.369 91.6869 192.791C93.8354 193.64 96.1034 194.332 98.4709 194.844C116.881 198.823 134.327 190.377 137.439 175.98C139.957 164.328 132.315 152.349 119.551 146.095Z"
        fill="white"
      />
      <ellipse
        cx="75.7686"
        cy="94.4971"
        rx="15.7401"
        ry="24.3056"
        transform="rotate(-14.2833 75.7686 94.4971)"
        fill="white"
      />
      <ellipse
        cx="110.903"
        cy="88.9942"
        rx="14.3778"
        ry="22.202"
        transform="rotate(11.6986 110.903 88.9942)"
        fill="white"
      />
      <ellipse
        cx="141.02"
        cy="119.517"
        rx="15.7401"
        ry="24.3056"
        transform="rotate(30.2288 141.02 119.517)"
        fill="white"
      />
      <ellipse
        cx="44.2222"
        cy="122.691"
        rx="12.9353"
        ry="22.1075"
        transform="rotate(-29.7776 44.2222 122.691)"
        fill="white"
      />
      <path
        d="M161.407 219.092C158.106 187.494 235.451 184.797 232.15 219.092C228.848 253.388 243.468 300.014 243.468 300.014H151.031C155.083 268.412 164.708 250.69 161.407 219.092Z"
        fill="#655B72"
      />
      <ellipse
        cx="177.285"
        cy="113.511"
        rx="22.9938"
        ry="32.4635"
        transform="rotate(-14.2833 177.285 113.511)"
        fill="#655B72"
      />
      <ellipse
        cx="211.847"
        cy="113.473"
        rx="25.5245"
        ry="32.4635"
        transform="rotate(18.5289 211.847 113.473)"
        fill="#655B72"
      />
      <ellipse
        cx="194.754"
        cy="173.852"
        rx="40.3985"
        ry="57.9899"
        transform="rotate(89.4535 194.754 173.852)"
        fill="#655B72"
      />
      <ellipse
        cx="205.262"
        cy="197.117"
        rx="40.3985"
        ry="57.9899"
        transform="rotate(89.4535 205.262 197.117)"
        fill="#655B72"
      />
      <ellipse
        cx="187.907"
        cy="194.457"
        rx="40.3985"
        ry="57.9899"
        transform="rotate(77.0326 187.907 194.457)"
        fill="#655B72"
      />
      <path
        d="M263.864 161.506C254.294 181.793 236.822 191.561 224.073 185.547C211.323 179.532 208.746 158.211 218.315 137.925C227.885 117.638 245.979 106.068 258.728 112.082C271.478 118.096 273.434 141.219 263.864 161.506Z"
        fill="#655B72"
      />
      <ellipse
        cx="150.731"
        cy="151.727"
        rx="26.6984"
        ry="41.9186"
        transform="rotate(-29.8779 150.731 151.727)"
        fill="#655B72"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M222.25 169.676C219.869 155.568 208.538 144.914 194.984 144.996C181.388 145.078 170.158 155.933 167.996 170.133C155.181 176.493 147.578 188.493 150.239 199.98C153.508 214.095 171.032 222.093 189.382 217.843C191.106 217.444 192.777 216.95 194.386 216.372C196.535 217.221 198.803 217.913 201.17 218.425C219.58 222.404 237.026 213.958 240.138 199.561C242.656 187.909 235.014 175.93 222.25 169.676Z"
        fill="white"
      />
      <ellipse
        cx="178.468"
        cy="118.078"
        rx="15.7401"
        ry="24.3056"
        transform="rotate(-14.2833 178.468 118.078)"
        fill="white"
      />
      <ellipse
        cx="213.602"
        cy="112.575"
        rx="14.3778"
        ry="22.202"
        transform="rotate(11.6986 213.602 112.575)"
        fill="white"
      />
      <ellipse
        cx="243.719"
        cy="143.098"
        rx="15.7401"
        ry="24.3056"
        transform="rotate(30.2288 243.719 143.098)"
        fill="white"
      />
      <ellipse
        cx="146.921"
        cy="146.272"
        rx="12.9353"
        ry="22.1075"
        transform="rotate(-29.7776 146.921 146.272)"
        fill="white"
      />
      <rect x="145" y="11" width="9" height="44" rx="4.5" fill="#3F3247" />
      <rect
        x="184.385"
        y="27.1297"
        width="9"
        height="32"
        rx="4.5"
        transform="rotate(40 184.385 27.1297)"
        fill="#3F3247"
      />
      <rect
        x="111"
        y="32.7851"
        width="9"
        height="32"
        rx="4.5"
        transform="rotate(-40 111 32.7851)"
        fill="#3F3247"
      />
    </g>
    <defs>
      <clipPath id="paws-clip0">
        <rect width="300" height="300" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Paws;
