import React from "react";

const Training = () => (
  <svg viewBox="0 0 300 300" fill="none">
    <g clipPath="url(#training-clip0)">
      <rect
        x="139.063"
        y="242.072"
        width="33"
        height="118.727"
        rx="15.971"
        transform="rotate(136.569 139.063 242.072)"
        stroke="currentColor"
        strokeWidth="12"
      />
      <rect
        x="98.632"
        y="248.676"
        width="33"
        height="72"
        rx="15.971"
        transform="rotate(136.569 98.632 248.676)"
        stroke="currentColor"
        strokeWidth="12"
      />
      <line
        x1="45.8025"
        y1="246.361"
        x2="36.1682"
        y2="255.482"
        stroke="currentColor"
        strokeWidth="12"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="160.605"
        y="58.1902"
        width="33"
        height="118.727"
        rx="15.971"
        transform="rotate(-43.4307 160.605 58.1902)"
        stroke="currentColor"
        strokeWidth="12"
      />
      <rect
        x="201.036"
        y="51.5865"
        width="33"
        height="72"
        rx="15.971"
        transform="rotate(-43.4307 201.036 51.5865)"
        stroke="currentColor"
        strokeWidth="12"
      />
      <line
        x1="253.866"
        y1="53.9012"
        x2="263.5"
        y2="44.7807"
        stroke="currentColor"
        strokeWidth="12"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="198.476"
        y1="101.83"
        x2="97.8958"
        y2="197.046"
        stroke="currentColor"
        strokeWidth="12"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="training-clip0">
        <rect width="300" height="300" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Training;