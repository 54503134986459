import React from "react";

const History = () => (
  <svg viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="44"
      y="126"
      width="43"
      height="138"
      rx="21.5"
      fill="white"
      stroke="currentColor"
      strokeWidth="12"
    />
    <rect
      x="129"
      y="76"
      width="43"
      height="188"
      rx="21.5"
      fill="white"
      stroke="currentColor"
      strokeWidth="12"
    />
    <rect
      x="214"
      y="36"
      width="43"
      height="228"
      rx="21.5"
      fill="white"
      stroke="currentColor"
      strokeWidth="12"
    />
  </svg>
);

export default History;
