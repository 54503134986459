import { loadStripe } from '@stripe/stripe-js';

export default function checkout(clientSecret) {
  return {
    elements: null,
    stripe: null,
    errorMsg: null,
    loading: false,
    baseUrl: process.env.BASE_URL, // eslint-disable-line
    async init() {

      // eslint-disable-next-line
      this.stripe = await loadStripe(process.env.STRIPE_PUBLIC_KEY);

      this.elements = this.stripe.elements({ clientSecret });
      const paymentElement = this.elements.create('payment');
      paymentElement.mount('#checkoutElement');

      if (window?.location) {
        this.baseUrl = window.location.origin;
      }
    },
    async completePurchase() {
      this.loading = true;

      const { error } = await this.stripe.confirmPayment({ 
        elements: this.elements,
        confirmParams: {
          return_url: `${this.baseUrl}/trainer/licenses`
        }
      });

      this.loading = false;

      if (error) {
        this.errorMsg = 'Failed to process payment. Please go back and try again.';
      }
    }
  };
}