import React, { Suspense } from 'react';
import useI18n from '../hooks/useI18n';

import '../helpers/fonticons';
import 'bootstrap/dist/js/bootstrap.min.js';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import queryClient from '../query/queryClient';
import ErrorBoundary from '../components/errorpages/ErrorBoundary';
import { BrowserRouter } from 'react-router-dom';
import FullPageSpinner from '../components/common/FullPageSpinner';
import ProContext from '../contexts/pro';
import store from '../redux/store';
import { Provider } from 'react-redux';
import Div100vh from 'react-div-100vh';

function isPro() {
  const meta = document.head.querySelector('meta[name=application-name]');
  return meta?.dataset?.isProTheme === 'true';
}

export default function AppRoot({ children, home }) {

  const { ready } = useI18n();

  if (!ready) return <FullPageSpinner />;

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary home={home ?? '/'}>
        <Div100vh>
          <BrowserRouter>
            <div className="app">

              <Suspense fallback={<FullPageSpinner />}>
                <ProContext.Provider value={isPro()}>
                  <Provider store={store}>
                    {children}
                  </Provider>
                </ProContext.Provider>
              </Suspense>

            </div>
          </BrowserRouter>
        </Div100vh>
      </ErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
