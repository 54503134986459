import React, { useContext } from "react";
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";

import { Training, Stopwatch, Settings, Hands, History, Paws, PawPrints } from "./icons";
import Logo from "./Logo";
import HeaderReload from "./HeaderReload";
import ProContext from "../../contexts/pro";
import ROUTES from "../../constants/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Icons = {
  training: Training,
  stopwatch: Stopwatch,
  settings: Settings,
  hands: Hands,
  history: History,
  paws: Paws,
  pawprints: PawPrints,
};

const Header = ({ icon }) => {
  const { t } = useTranslation();
  const isPro = useContext(ProContext);
  const Icon = Icons[icon];

  const hasIcon = !!Icon;

  return (
    <header
      className={classNames("page-header", { "page-header--has-icon": hasIcon })}
    >
      <Container className="page-header__container">
        <Logo isPro={isPro} />
        <HeaderReload />

        { hasIcon && !isPro &&
          <Link to={ROUTES.OWNER.HOWTO} className="page-header__cta">
            <FontAwesomeIcon icon="video" className="mr-1" />
            {t('heroes.how-to-videos')}
          </Link>
        }
      </Container>
      { hasIcon && 
        <div className="page-header__icon">
          <Icon />
        </div>
      }
    </header>
  );
};

Header.propTypes = {
  icon: PropTypes.string,
};

Header.defaultProps = {
  icon: undefined,
};

export default Header;
