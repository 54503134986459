import React from "react";

const House = () => (
  <svg viewBox="0 0 300 300" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.0704 160.347L86.1542 269.152C88.0046 275.844 94.094 280.478 101.037 280.478H201.11C208.053 280.478 214.143 275.844 215.993 269.152L246.077 160.347H233.753L204.545 265.987C204.118 267.531 202.713 268.6 201.11 268.6H101.037C99.4349 268.6 98.0296 267.531 97.6026 265.987L68.394 160.347H56.0704Z"
      fill="currentColor"
    />
    <mask id="house-path-2-inside-1" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150.313 82.0778L70.9811 158.023C60.2335 168.312 42.8083 168.312 32.0607 158.023C21.3131 147.734 21.3131 131.053 32.0607 120.764L130.24 26.7763C135.242 21.9875 141.691 19.4276 148.24 19.0966C155.976 18.4624 163.942 20.95 169.903 26.5753L268.808 119.91C279.626 130.118 279.745 146.794 269.075 157.156C258.405 167.518 240.985 167.643 230.168 157.435L150.313 82.0778Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.313 82.0778L70.9811 158.023C60.2335 168.312 42.8083 168.312 32.0607 158.023C21.3131 147.734 21.3131 131.053 32.0607 120.764L130.24 26.7763C135.242 21.9875 141.691 19.4276 148.24 19.0966C155.976 18.4624 163.942 20.95 169.903 26.5753L268.808 119.91C279.626 130.118 279.745 146.794 269.075 157.156C258.405 167.518 240.985 167.643 230.168 157.435L150.313 82.0778Z"
      fill="white"
      fillOpacity="0.01"
    />
    <path
      d="M150.313 82.0778L157.664 74.2877L150.26 67.3004L142.906 74.3405L150.313 82.0778ZM70.9811 158.023L63.5741 150.286L70.9811 158.023ZM32.0607 158.023L39.4677 150.286L39.4677 150.286L32.0607 158.023ZM32.0607 120.764L39.4677 128.501H39.4677L32.0607 120.764ZM130.24 26.7763L122.833 19.039L122.833 19.039L130.24 26.7763ZM148.24 19.0966L148.78 29.7941L148.948 29.7857L149.115 29.772L148.24 19.0966ZM169.903 26.5753L162.552 34.3655V34.3655L169.903 26.5753ZM268.808 119.91L276.159 112.119V112.119L268.808 119.91ZM269.075 157.156L276.537 164.84L269.075 157.156ZM230.168 157.435L237.519 149.645L230.168 157.435ZM142.906 74.3405L63.5741 150.286L78.3881 165.76L157.72 89.8151L142.906 74.3405ZM63.5741 150.286C56.9685 156.609 46.0733 156.609 39.4677 150.286L24.6537 165.76C39.5432 180.014 63.4985 180.014 78.3881 165.76L63.5741 150.286ZM39.4677 150.286C33.1257 144.215 33.1257 134.573 39.4677 128.501L24.6537 113.027C9.50055 127.533 9.50055 151.254 24.6537 165.76L39.4677 150.286ZM39.4677 128.501L137.646 34.5136L122.833 19.039L24.6537 113.027L39.4677 128.501ZM137.646 34.5136C140.677 31.6127 144.641 30.0034 148.78 29.7941L147.699 8.3991C138.741 8.85189 129.807 12.3624 122.833 19.039L137.646 34.5136ZM149.115 29.772C154.027 29.3693 158.943 30.9605 162.552 34.3655L177.254 18.7852C168.94 10.9395 157.925 7.55555 147.365 8.42125L149.115 29.772ZM162.552 34.3655L261.457 127.7L276.159 112.119L177.254 18.7852L162.552 34.3655ZM261.457 127.7C267.839 133.722 267.908 143.359 261.613 149.472L276.537 164.84C291.583 150.229 291.413 126.514 276.159 112.119L261.457 127.7ZM261.613 149.472C255.056 155.84 244.166 155.918 237.519 149.645L222.816 165.225C237.804 179.369 261.753 179.197 276.537 164.84L261.613 149.472ZM237.519 149.645L157.664 74.2877L142.961 89.868L222.816 165.225L237.519 149.645Z"
      fill="currentColor"
      mask="url(#house-path-2-inside-1)"
    />
    <mask id="house-path-4-inside-2" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.718 160.347C129.038 160.347 113.085 176.301 113.085 195.981V280.315H189.103V195.981C189.103 176.301 173.149 160.347 153.469 160.347H148.718Z"
      />
    </mask>
    <path
      d="M113.085 280.315H101.207V292.193H113.085V280.315ZM189.103 280.315V292.193H200.981V280.315H189.103ZM124.962 195.981C124.962 182.861 135.598 172.225 148.718 172.225V148.469C122.478 148.469 101.207 169.741 101.207 195.981H124.962ZM124.962 280.315V195.981H101.207V280.315H124.962ZM113.085 292.193H189.103V268.437H113.085V292.193ZM177.225 195.981V280.315H200.981V195.981H177.225ZM153.469 172.225C166.589 172.225 177.225 182.861 177.225 195.981H200.981C200.981 169.741 179.709 148.469 153.469 148.469V172.225ZM148.718 172.225H153.469V148.469H148.718V172.225Z"
      fill="currentColor"
      mask="url(#house-path-4-inside-2)"
    />
    <line
      x1="83.3897"
      y1="243.493"
      x2="122.587"
      y2="243.493"
      stroke="currentColor"
      strokeWidth="11.8779"
    />
    <line
      x1="183.164"
      y1="243.493"
      x2="217.61"
      y2="243.493"
      stroke="currentColor"
      strokeWidth="11.8779"
    />
  </svg>
);

export default House;
