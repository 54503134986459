import React from "react";

const Chat = () => (
  <svg viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="chat-path-1-inside-1" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 21C16.299 21 21 16.299 21 10.5C21 4.70101 16.299 0 10.5 0C4.70101 0 0 4.70101 0 10.5C0 13.5975 1.34121 16.3816 3.47455 18.3035L1.51735 20.2357C1.27432 20.4757 1.43964 20.8898 1.7811 20.8964L9.92291 21.0538C10.0033 21.0553 10.0766 21.0328 10.1376 20.9939C10.2579 20.9979 10.3787 21 10.5 21Z"
      />
    </mask>
    <path
      d="M3.47455 18.3035L4.12544 18.9628L4.82451 18.2727L4.09466 17.6152L3.47455 18.3035ZM1.51735 20.2357L0.86646 19.5764H0.86646L1.51735 20.2357ZM1.7811 20.8964L1.799 19.9701H1.799L1.7811 20.8964ZM9.92291 21.0538L9.94081 20.1275H9.94081L9.92291 21.0538ZM10.1376 20.9939L10.169 20.0679L9.88131 20.0582L9.63873 20.2132L10.1376 20.9939ZM20.0735 10.5C20.0735 15.7873 15.7873 20.0735 10.5 20.0735V21.9265C16.8107 21.9265 21.9265 16.8107 21.9265 10.5H20.0735ZM10.5 0.926471C15.7873 0.926471 20.0735 5.21269 20.0735 10.5H21.9265C21.9265 4.18933 16.8107 -0.926471 10.5 -0.926471V0.926471ZM0.926471 10.5C0.926471 5.21269 5.21269 0.926471 10.5 0.926471V-0.926471C4.18933 -0.926471 -0.926471 4.18933 -0.926471 10.5H0.926471ZM4.09466 17.6152C2.1481 15.8616 0.926471 13.3241 0.926471 10.5H-0.926471C-0.926471 13.8708 0.534315 16.9017 2.85444 18.9919L4.09466 17.6152ZM2.16824 20.895L4.12544 18.9628L2.82366 17.6442L0.86646 19.5764L2.16824 20.895ZM1.799 19.9701C2.27704 19.9793 2.5085 20.5591 2.16824 20.895L0.86646 19.5764C0.0401342 20.3922 0.602249 21.8003 1.7632 21.8227L1.799 19.9701ZM9.94081 20.1275L1.799 19.9701L1.7632 21.8227L9.90501 21.9801L9.94081 20.1275ZM9.63873 20.2132C9.72761 20.1564 9.8334 20.1254 9.94081 20.1275L9.905 21.9801C10.1733 21.9852 10.4257 21.9093 10.6365 21.7745L9.63873 20.2132ZM10.5 20.0735C10.3892 20.0735 10.2789 20.0717 10.169 20.0679L10.1062 21.9198C10.237 21.9242 10.3682 21.9265 10.5 21.9265V20.0735Z"
      fill="currentColor"
      mask="url(#chat-path-1-inside-1)"
    />
  </svg>
);

export default Chat;
