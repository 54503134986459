import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlus,
  faMinus,
  faEdit,
  faTrashAlt,
  faRedoAlt,
  faPaw,
  faSignOutAlt,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faMinusCircle,
  faStar,
  faPaperPlane,
  faTasks,
  faComment,
  faCircle,
  faEye,
  faExchangeAlt,
  faDoorClosed,
  faStopwatch20,
  faCheck,
  faTimes,
  faSyncAlt,
  faQuestionCircle,
  faSearch,
  faDog,
  faBirthdayCake,
  faUserPlus,
  faUserMinus,
  faCheckCircle,
  faDotCircle,
  faEllipsisH,
  faHeart,
  faUsers,
  faUnlock,
  faExclamationTriangle,
  faHandHoldingHeart,
  faVideo,
  faExpand,
  faCompress,
  faAt,
  faLock,
  faMoneyBillWave,
} from "@fortawesome/free-solid-svg-icons";

const icons = [
  faPlus,
  faMinus,
  faEdit,
  faTrashAlt,
  faRedoAlt,
  faPaw,
  faSignOutAlt,
  faChevronLeft,
  faMinusCircle,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faStar,
  faPaperPlane,
  faTasks,
  faComment,
  faCircle,
  faEye,
  faExchangeAlt,
  faDoorClosed,
  faStopwatch20,
  faCheck,
  faTimes,
  faSyncAlt,
  faQuestionCircle,
  faSearch,
  faDog,
  faBirthdayCake,
  faUserPlus,
  faUserMinus,
  faCheckCircle,
  faDotCircle,
  faEllipsisH,
  faHeart,
  faUsers,
  faUnlock,
  faExclamationTriangle,
  faHandHoldingHeart,
  faVideo,
  faExpand,
  faCompress,
  faAt,
  faLock,
  faMoneyBillWave,
];

icons.forEach(icon => library.add(icon));
