import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useMedia from "../../hooks/useMedia";

const HeaderReload = ({ isVerticallyAligned }) => {
  const isPWA = useMedia("(display-mode: standalone)");

  return isPWA ? (
    <button
      type="button"
      className={classNames("page-header__reload", {
        "page-header__reload--center": isVerticallyAligned
      })}
      onClick={() => location.reload()}
    >
      <FontAwesomeIcon icon="sync-alt" />
    </button>
  ) : null;
};

export default HeaderReload;
