import React from "react";

const Stopwatch = () => (
  <svg viewBox="0 0 300 300" fill="none">
    <circle
      cx="150"
      cy="161"
      r="113"
      fill="white"
      fillOpacity="0.01"
      stroke="#3f324f"
      strokeWidth="10"
    />
    <circle
      cx="150"
      cy="161"
      r="14.5"
      fill="white"
      fillOpacity="0.01"
      stroke="#3f324f"
      strokeWidth="7"
    />
    <line
      x1="138.655"
      y1="149.493"
      x2="80.8294"
      y2="106.596"
      stroke="#3f324f"
      strokeWidth="8"
      strokeLinecap="round"
    />
    <line
      x1="150"
      y1="49"
      x2="150"
      y2="25"
      stroke="#3f324f"
      strokeWidth="8"
      strokeLinecap="round"
    />
    <line
      x1="126"
      y1="24"
      x2="173.652"
      y2="24"
      stroke="#3f324f"
      strokeWidth="8"
      strokeLinecap="round"
    />
    <line
      x1="230"
      y1="78.251"
      x2="242.343"
      y2="65.9079"
      stroke="#3f324f"
      strokeWidth="8"
      strokeLinecap="round"
    />
  </svg>
);

export default Stopwatch;