import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

import '@hotwired/turbo-rails';
import * as htmx from 'htmx.org';
import queryClient from '../query/queryClient';

import Alpine from 'alpinejs';
import checkAll from '../alpine/checkAll';
import checkout from '../alpine/checkout';
import collectPayment from '../alpine/collectPayment';

export default function createEntrypoint(Component) {

  window.htmx = htmx;

  Alpine.data('checkAll', checkAll);
  Alpine.data('checkout', checkout);
  Alpine.data('collectPayment', collectPayment);

  // a message for a confirmation modal
  Alpine.store('confirmation', {
    element: null,
    message: '',
  });
  Alpine.start();

  document.addEventListener('DOMContentLoaded', () => {
    const container = document.getElementById('react-container');
    if (container) {
      render(<Component />, container);
    }
  });

  // after Turbo navigates and re-renders the body, 
  // tell the React app to re-render so that it updates according 
  // to the new page URL
  document.addEventListener('turbo:before-render', () => {
    const container = document.getElementById('react-container');
    if (container) {
      unmountComponentAtNode(container);
    }
  });
  document.addEventListener('turbo:render', () => {
    const container = document.getElementById('react-container');
    if (container) {
      render(<Component />, container);
    }

    // let HTMX attach to any new elements rendered by Turbo
    htmx.process(document.body);
  });

  window.addEventListener('load', () => {

    // eslint-disable-next-line
    if (process.env.NODE_ENV === 'development') return;

    if (navigator.serviceWorker) {
      navigator.serviceWorker.register('/service-worker.js').then(registration => {
        console.log('ServiceWorker registered: ', registration);
      }).catch(registrationError => {
        console.log('Service worker registration failed: ', registrationError);
      });
    }
  });

  if (window.Cypress) {
    window.__queryClient__ = queryClient;
  }

}