import React from 'react';

const BannerJulie = () => (
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="70 0 580 169" className="julie-logo">
    <path className="primary-text"
      d="M100.36,89.37c0,1.63-.2,3.09-.6,4.39-.4,1.3-1,2.42-1.79,3.34-.79,.92-1.78,1.62-2.97,2.12-1.19,.49-2.56,.74-4.13,.74-.72,0-1.43-.04-2.12-.13-.69-.09-1.42-.22-2.18-.41l.28-3.19c.03-.29,.14-.53,.33-.71,.19-.18,.48-.27,.85-.27,.23,0,.52,.05,.89,.14s.83,.14,1.39,.14c.76,0,1.43-.11,2.02-.33,.59-.22,1.08-.57,1.47-1.05,.39-.48,.69-1.1,.89-1.87,.2-.77,.3-1.7,.3-2.79v-18.73h5.37v18.61Z" />
    <path className="primary-text"
      d="M122.61,95.3c1.04,0,1.97-.17,2.79-.52,.82-.35,1.51-.83,2.08-1.46,.57-.63,1-1.39,1.3-2.28,.3-.89,.45-1.89,.45-3v-17.3h5.37v17.3c0,1.72-.28,3.3-.83,4.76-.55,1.46-1.35,2.72-2.39,3.77-1.04,1.06-2.3,1.88-3.78,2.48-1.48,.59-3.14,.89-4.99,.89s-3.51-.3-4.99-.89c-1.48-.59-2.73-1.42-3.76-2.48-1.03-1.06-1.82-2.32-2.38-3.77-.55-1.46-.83-3.05-.83-4.76v-17.3h5.37v17.28c0,1.11,.15,2.1,.45,3,.3,.89,.73,1.65,1.29,2.29,.56,.63,1.25,1.12,2.07,1.47,.82,.35,1.75,.52,2.79,.52Z" />
    <path className="primary-text" d="M150.23,95.2h11.56v4.43h-16.94v-28.88h5.37v24.45Z" />
    <path className="primary-text" d="M176.35,99.64h-5.39v-28.88h5.39v28.88Z" />
    <path className="primary-text" d="M205.85,70.76v4.27h-12.8v8.01h10.08v4.13h-10.08v8.17h12.8v4.29h-18.21v-28.88h18.21Z" />
    <path className="primary-text"
      d="M231.21,70.79c.16,.02,.3,.06,.43,.12s.25,.15,.37,.26c.12,.11,.25,.26,.4,.45l15.16,19.31c-.05-.47-.09-.92-.11-1.37-.02-.45-.03-.86-.03-1.25v-17.56h4.73v28.88h-2.78c-.43,0-.78-.07-1.06-.2-.28-.13-.55-.37-.82-.72l-15.1-19.23c.04,.43,.07,.85,.09,1.27,.02,.42,.03,.8,.03,1.15v17.73h-4.73v-28.88h2.82c.24,0,.44,.01,.6,.03Z" />
    <path className="primary-text"
      d="M288.17,99.64h-4.15c-.47,0-.85-.12-1.15-.35-.3-.23-.52-.52-.65-.87l-2.16-5.89h-11.96l-2.16,5.89c-.11,.31-.32,.59-.63,.84-.31,.25-.7,.38-1.15,.38h-4.17l11.34-28.88h5.49l11.34,28.88Zm-18.69-10.9h9.21l-3.52-9.61c-.16-.43-.34-.93-.53-1.51-.19-.58-.38-1.21-.57-1.89-.19,.68-.37,1.31-.55,1.9-.18,.59-.36,1.1-.53,1.54l-3.52,9.57Z" />
    <path className="primary-text" d="M301.99,99.64h-5.39v-28.88h5.39v28.88Z" />
    <path className="primary-text"
      d="M328.75,76.01c-.15,.29-.32,.5-.51,.62-.19,.12-.42,.18-.69,.18s-.57-.1-.9-.31-.73-.44-1.18-.69c-.45-.25-.98-.48-1.59-.69-.61-.21-1.32-.31-2.15-.31-.75,0-1.39,.09-1.95,.27-.55,.18-1.02,.43-1.4,.75-.38,.32-.66,.7-.85,1.15-.19,.45-.28,.94-.28,1.47,0,.68,.19,1.24,.57,1.7,.38,.45,.88,.84,1.51,1.16,.63,.32,1.34,.61,2.14,.86,.8,.25,1.61,.52,2.45,.81,.83,.29,1.65,.62,2.45,1,.8,.38,1.51,.86,2.14,1.44,.63,.58,1.13,1.29,1.51,2.13,.38,.84,.57,1.86,.57,3.06,0,1.3-.22,2.53-.67,3.66-.45,1.14-1.1,2.13-1.96,2.98-.86,.85-1.91,1.51-3.15,2-1.24,.49-2.66,.73-4.25,.73-.92,0-1.82-.09-2.72-.27-.89-.18-1.75-.44-2.57-.77-.82-.33-1.59-.73-2.3-1.2-.71-.47-1.35-.99-1.91-1.56l1.56-2.58c.15-.19,.32-.34,.53-.47,.21-.13,.44-.19,.69-.19,.33,0,.69,.14,1.08,.41,.39,.27,.84,.58,1.37,.91,.52,.33,1.14,.64,1.86,.91,.71,.27,1.57,.41,2.57,.41,1.53,0,2.72-.36,3.56-1.09,.84-.73,1.26-1.77,1.26-3.13,0-.76-.19-1.38-.57-1.86-.38-.48-.88-.88-1.51-1.21-.63-.33-1.34-.6-2.14-.83-.8-.23-1.61-.47-2.44-.74-.83-.27-1.64-.59-2.44-.96-.8-.37-1.51-.86-2.14-1.46-.63-.6-1.13-1.35-1.51-2.25s-.57-2.01-.57-3.33c0-1.05,.21-2.08,.63-3.08,.42-1,1.03-1.88,1.84-2.66,.8-.77,1.79-1.39,2.97-1.86,1.17-.47,2.51-.7,4.01-.7,1.7,0,3.28,.27,4.71,.8,1.44,.53,2.66,1.28,3.67,2.24l-1.32,2.54Z" />
    <path className="primary-text"
      d="M354.63,88.98c.19,.46,.38,.92,.57,1.39,.19-.48,.38-.95,.58-1.42,.2-.47,.41-.91,.64-1.34l8.55-16c.11-.2,.22-.36,.33-.48,.11-.12,.24-.21,.38-.26s.3-.09,.47-.1c.17-.01,.38-.02,.62-.02h4.05v28.88h-4.73v-18.65c0-.35,.01-.73,.03-1.14,.02-.41,.05-.83,.09-1.26l-8.73,16.38c-.2,.37-.46,.66-.78,.87-.32,.21-.69,.31-1.12,.31h-.74c-.43,0-.8-.1-1.12-.31-.32-.21-.58-.5-.78-.87l-8.85-16.44c.05,.44,.09,.87,.11,1.29,.02,.42,.03,.81,.03,1.17v18.65h-4.73v-28.88h4.05c.24,0,.45,0,.62,.02,.17,.01,.33,.05,.47,.1,.14,.05,.27,.14,.39,.26,.12,.12,.23,.28,.34,.48l8.63,16.04c.23,.43,.44,.87,.63,1.33Z" />
    <path className="primary-text" d="M387.49,99.64h-5.39v-28.88h5.39v28.88Z" />
    <path className="primary-text" d="M419.12,70.76v4.41h-8.69v24.47h-5.37v-24.47h-8.73v-4.41h22.79Z" />
    <path className="primary-text" d="M451.7,99.64h-5.41v-12.62h-13.54v12.62h-5.41v-28.88h5.41v12.42h13.54v-12.42h5.41v28.88Z" />
    <path className="primary-text"
      d="M465.58,69.04c.47,.75,.81,1.49,1.03,2.24,.22,.75,.33,1.49,.33,2.24,0,1.33-.34,2.6-1.01,3.81-.67,1.21-1.64,2.33-2.91,3.35l-1.54-.94c-.13-.08-.23-.18-.28-.29-.05-.11-.08-.22-.08-.33,0-.25,.09-.47,.26-.64,.17-.2,.36-.43,.55-.69s.37-.55,.53-.86c.16-.31,.29-.65,.39-1.03,.1-.37,.15-.77,.15-1.2,0-.45-.07-.94-.22-1.45-.15-.51-.39-1.06-.74-1.63-.12-.17-.18-.35-.18-.54,0-.43,.25-.72,.74-.9l2.98-1.16Z" />
    <path className="primary-text"
      d="M491.58,76.01c-.15,.29-.32,.5-.51,.62-.19,.12-.42,.18-.69,.18s-.57-.1-.9-.31-.73-.44-1.18-.69c-.45-.25-.98-.48-1.59-.69-.61-.21-1.32-.31-2.15-.31-.75,0-1.39,.09-1.95,.27-.55,.18-1.02,.43-1.4,.75-.38,.32-.66,.7-.85,1.15-.19,.45-.28,.94-.28,1.47,0,.68,.19,1.24,.57,1.7,.38,.45,.88,.84,1.51,1.16,.63,.32,1.34,.61,2.14,.86,.8,.25,1.61,.52,2.45,.81,.83,.29,1.65,.62,2.45,1,.8,.38,1.51,.86,2.14,1.44,.63,.58,1.13,1.29,1.51,2.13,.38,.84,.57,1.86,.57,3.06,0,1.3-.22,2.53-.67,3.66-.45,1.14-1.1,2.13-1.96,2.98-.86,.85-1.91,1.51-3.15,2-1.24,.49-2.66,.73-4.25,.73-.92,0-1.82-.09-2.72-.27-.89-.18-1.75-.44-2.57-.77-.82-.33-1.59-.73-2.3-1.2-.71-.47-1.35-.99-1.91-1.56l1.56-2.58c.15-.19,.32-.34,.53-.47,.21-.13,.44-.19,.69-.19,.33,0,.69,.14,1.08,.41,.39,.27,.84,.58,1.37,.91,.52,.33,1.14,.64,1.86,.91,.71,.27,1.57,.41,2.57,.41,1.53,0,2.72-.36,3.56-1.09,.84-.73,1.26-1.77,1.26-3.13,0-.76-.19-1.38-.57-1.86-.38-.48-.88-.88-1.51-1.21-.63-.33-1.34-.6-2.14-.83-.8-.23-1.61-.47-2.44-.74-.83-.27-1.64-.59-2.44-.96-.8-.37-1.51-.86-2.14-1.46-.63-.6-1.13-1.35-1.51-2.25-.38-.9-.57-2.01-.57-3.33,0-1.05,.21-2.08,.63-3.08,.42-1,1.03-1.88,1.84-2.66,.8-.77,1.79-1.39,2.97-1.86,1.17-.47,2.51-.7,4.01-.7,1.7,0,3.28,.27,4.71,.8,1.44,.53,2.66,1.28,3.67,2.24l-1.32,2.54Z" />
  </svg>
);
export default BannerJulie;